import { Route, Redirect, Switch } from "react-router-dom";
import React, { Suspense, lazy, Fragment, useEffect } from "react";
import Loader from "react-loaders";
import { useAuth } from '../AuthContext';
import { ToastContainer } from "react-toastify";

//const Dashboards = lazy(() => import("../../DemoPages/Dashboards"));
const Components = lazy(() => import("../../DemoPages/Components"));
const UserPages = lazy(() => import("../../Pages/UserPages"));
const Dashboards = lazy(() => import("../../Pages/Dashboards"));
const KeywordSearch = lazy(() => import("../../Pages/SearchSimulation/KeywordSearch"));
const VectorSearch = lazy(() => import("../../Pages/SearchSimulation/VectorSearch"));
const AIChatbot = lazy(() => import("../../Pages/SearchSimulation/AIChatbot"));
const Machinelearning = lazy(() => import("../../Pages/SearchSimulation/MachineLearning"));
const KibanaVisualization = lazy(() => import("../../Pages/Visualization/KibanaVisualization"));
const CustomDictionary = lazy(() => import("../../Pages/Optimization/CustomDictionary"));

// 수정전

const LoaderComponent = () => (
    <div className="loader-container">
        <div className="loader-container-inner">
            <div className="text-center">
                <Loader type="ball-pulse-rise"/>
            </div>
            <h6 className="mt-5">
                잠시만 기다려 주세요
                <small>최상의 검색 결과를 위해 페이지를 조립중입니다.</small>
            </h6>
        </div>
    </div>
);

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { isLoggedIn } = useAuth();

    return (
        <Route {...rest} render={(props) => (
            isLoggedIn ? <Component {...props} /> : <Redirect to="/pages/login" />
        )} />
    );
};


const AppMain = () => {

    const { isLoggedIn } = useAuth();

    useEffect(() => {
        if (!isLoggedIn) {
            return <Redirect to="/#/pages/login" />;
        }
      }, []);

    return (
        <Fragment>
        <Suspense fallback={<LoaderComponent />}>
                <Switch>
                    <PrivateRoute path="/keyword-search" component={KeywordSearch} />
                    <PrivateRoute path="/vector-search" component={VectorSearch} />
                    <PrivateRoute path="/ai-chatbot" component={AIChatbot} />
                    <PrivateRoute path="/machinelearning" component={Machinelearning} />
                    <PrivateRoute path="/visualization" component={KibanaVisualization} />
                    <PrivateRoute path="/components" component={Components} />
                    <PrivateRoute path="/dashboards" component={Dashboards} />
                    <PrivateRoute path="/custom-dictionary" component={CustomDictionary} />
                    <Route exact path="/" render={() => (<Redirect to="/pages/login"/>)}/>
                    <Route path="/pages" component={UserPages}/>
                </Switch>
            </Suspense>
            <ToastContainer />
        </Fragment>
    )
};

export default AppMain;
